import Swup from "swup";
import SwupFragmentPlugin from "@swup/fragment-plugin";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupMorphPlugin from "swup-morph-plugin";

const documentLocale = document.documentElement.lang;
const allowedLocales = ['cn', 'en', 'de'];
const locale = allowedLocales.includes(documentLocale) ? documentLocale : 'en';

const products = {
    en: 'products',
    cn: 'products',
    de: 'produkte',
}[locale];

const search = {
    en: 'search',
    cn: 'search',
    de: 'suche',
}[locale];

export const brandCategoryPattern = `/${locale}/${products}/:category{/:subcategory}`;
export const brandEntryPattern = `/${locale}/brands`;
export const searchPattern = `/${locale}/${search}`;
export const homePattern = `/${locale}`;
export const homePatternWithStoryPage = `/${locale}/\\?page=:page`;

export default new Swup({
    containers: ["#main", "#header"],
    plugins: [
        new SwupMorphPlugin({
            containers: ["#headSwitcher", "#footerSwitcher"],
        }),
        new SwupFragmentPlugin({
            rules: [
                {
                    from: brandCategoryPattern,
                    to: brandCategoryPattern,
                    containers: ["#header"],
                },
                {
                    from: homePattern,
                    to: homePatternWithStoryPage,
                    containers: ["#stories", "#loadMoreStories"],
                },
                {
                    from: homePatternWithStoryPage,
                    to: homePatternWithStoryPage,
                    containers: ["#stories", "#loadMoreStories"],
                },
                {
                    from: searchPattern,
                    to: searchPattern,
                    containers: ["#searchResults"],
                },
            ],
            debug: true,
        }),
        new SwupPreloadPlugin(),
        new SwupProgressPlugin({
            delay: 700,
        }),
    ],
});
