import animationData from "../../svg/subscribed.json";

export default () => ({
    open: false,
    form: {
        firstName: "",
        lastName: "",
        email: "",
    },
    showError: false,
    error: false,
    submitting: false,
    submitted: false,
    csrfField: {},
    snaptchaField: {},

    animationLoaded: false,
    lottieData: JSON.stringify(animationData),
    animationHalfway: false,

    init() {
        this.$watch("open", this.openChanged.bind(this));
    },

    async openChanged () {
        if (this.open) {
            this.loadAnimation();
            this.updateTokens();
        }

        // Reset the form after closing it after a submit
        if (!this.open && this.submitted) {
            setTimeout(() => {
                this.form = {
                    firstName: "",
                    lastName: "",
                    email: "",
                };
                this.submitted = false;
                this.animationHalfway = false;
                this.resetAnimation();
            }, 200);
        }
    },

    updateTokens() {
        fetch("/actions/blitz/csrf/json")
            .then((result) => result.json())
            .then((res) => {
                this.csrfField = res;
            });

        fetch('/actions/snaptcha/field/get-field', {
            headers: {
                Accept: 'application/json'
            }})
            .then(result => result.json())
            .then(result => {
                this.snaptchaField = result;
            });
    },

    toggle(e) {
        this.open = true;
    },

    async submit(e) {
        if (this.submitting) {
            return;
        }

        if (this.submitted) {
            this.open = false;
            return;
        }

        // keep the form open
        this.open = true;

        // mark as submitting
        this.submitting = true;

        this.playAnimation();

        const formElement = this.$refs.form;
        const formData = new FormData(formElement);

        const documentLocale = document.documentElement.lang;
        const allowedLocales = ['cn', 'en', 'de'];
        const locale = allowedLocales.includes(documentLocale) ? documentLocale : 'en';

        // add csrf to form data
        formData.append(this.csrfField.param, this.csrfField.token);
        formData.append(this.snaptchaField.name, this.snaptchaField.value);
        formData.append('fields[locale]', locale);

        fetch("/actions/campaign/forms/subscribe", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: formData,
        })
            .then(async (response) => {
                if(response.ok === false) {
                    const red = await response.json();
                    throw new Error(red.errors?.email?.length > 0 ? "email" : "other");
                }
                return response.json();
            })
            .then(() => {
                this.submitting = false;
                this.submitted = true;
                this.finishAnimation();
                this.updateTokens();
            })
            .catch((error) => {
                console.log(error);
                this.submitting = false;
                this.showError = true;
                this.error = error.message === 'email' ? 'email' : 'other';
                this.resetAnimation();
                this.updateTokens();
            });
    },

    async loadAnimation() {
        if (this.animationLoaded) {
            return;
        }
        // const animationData = await import("../../svg/subscribed.json");
        // this.lottieData = JSON.stringify(animationData);
        await import("@dotlottie/player-component");
        this.animationLoaded = true;
    },

    async playAnimation() {
        if (!this.animationLoaded) {
            await this.loadAnimation();
        }

        this.$refs.subscribePlayer.play();
        setTimeout(() => {
            if (this.submitting) {
                this.$refs.subscribePlayer.pause();
            }
        }, 500);
    },

    resetAnimation() {
        if (!this.animationLoaded) {
            return;
        }

        this.$refs.subscribePlayer.stop();
        this.$refs.subscribePlayer.reset();
    },

    finishAnimation() {
        this.animationHalfway = true;
        this.$refs.subscribePlayer.play();
    },

    onClickError() {
        this.showError = false;
        this.open = true;
        this.$refs.emailInput.focus();
    },
});
